<template>
  <v-container class="mt-6">
    <!-- <div class="text-center">
        <v-progress-circular v-if="loading" size="60" indeterminate color="primary" />
    </div> -->
    <v-row>
      <v-card-title>
        <v-breadcrumbs class="text-uppercase font-weight-bolder" :items="itemsLink" />
        <v-spacer />
      </v-card-title>

      <v-col cols="12">
        <base-card>
          <v-card-text class="ps-6 d-flex justify-space-between align-center flex-wrap">
            <div class="my-2">
              <h4 class="">Pesquisando por "{{ $route.params.keyword }}"</h4>
              <p class="gray--text text--darken-1 mb-0" v-if="produtos.length > 1">
                <span class="font-weight-bold"> {{ produtos.length }}</span>
                produtos encontrados
              </p>
              <p class="gray--text text--darken-1 mb-0" v-if="produtos.length == 1">
                <span class="font-weight-bold"> {{ produtos.length }}</span>
                produto encontrado
              </p>
              <p class="gray--text text--darken-1 mb-0" v-if="produtos.length == 0 && !offline">
                <span class="font-weight-bold red--text">
                  Nenhum resultado encontrado</span>
              </p>
              <p class="gray--text text--darken-1 mb-0" v-if="offline">
                <span class="font-weight-bold red--text">
                  Não foi possivel a conexão com o servidor!</span>
              </p>
            </div>
            <div class="d-flex align-center flex-wrap">
              <div class="grey--text text--darken-1 me-2 my-2">
                Ordenar por:
              </div>

              <v-autocomplete class="border me-5 mt-6" :disabled="produtos.length==0" outlined dense placeholder="Padrão" :items="ordersBy" item-value="valor" item-text="designacao" @change="orderByParameter" v-model="params" />

              <div v-if="isWeb" class="grey--text text--darken-1 me-2 my-2">Ver:</div>
              <v-btn v-if="isWeb" :disabled="produtos.length==0" @click="showDefaultList('default')" title="Ver Produtos em Forma de Grade" icon>
                <img v-svg-inline class="icon" src="@/assets/images/icons/grid.svg" alt="" />
              </v-btn>

              <v-btn v-if="isWeb" :disabled="produtos.length==0" @click="showDefaultList('list')" title="Ver Produtos em Forma de Lista" icon>
                <img v-svg-inline class="icon" src="@/assets/images/icons/menu.svg" alt="" />
              </v-btn>
            </div>
          </v-card-text>
          <v-row v-if="loading">
            <v-col cols="12">
              <v-progress-linear indeterminate color="secondary"></v-progress-linear>
            </v-col>
          </v-row>
        </base-card>
      </v-col>

      <v-col cols="12">
        <div>
          <!-- <div class="box-overlay" :class="{ open: isSidebar }" @click="isSidebar = !isSidebar"></div> -->

          <!-- <div name="sidebar" class="box-sidebar pb-3 shadow-sm" :class="{ open: isSidebar }">
            <h5 class="ps-6 mt-6">CATEGORIAS</h5>
            <div v-for="category in categories" :key="category.id">
              <v-expansion-panels class="py-0" flat v-if="category.subcategorias.length > 0">
                <v-expansion-panel class="pa-0">
                  <v-expansion-panel-header class="grey--text text--darken-2 py-0 text-uppercase">
                    {{ category.designacao }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div class="mb-2 mt-2 text-uppercase" v-for="sub_categoria in category.subcategorias" :key="sub_categoria.id">
                      <a href="#" class="text-decoration-none grey--text text--darken-2 ms-6 text-uppercase">
                        {{ sub_categoria.designacao }}
                      </a>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>

            <div class="ps-6 pb-3" v-for="categoria in categories" :key="categoria.id">
              <a href="#" v-if="categoria.subcategorias.length == 0" class="text-decoration-none text-uppercase grey--text text--darken-2">{{ categoria.designacao }}</a>
            </div>
            <v-divider class="mx-6 my-1"></v-divider>

            <div class="mx-6 pt-3">
              <h5 class="">Categorias</h5>
              <v-range-slider class="mt-10" :value="[1, 2]" min="0" max="3" tick-size="4">
                <template v-slot:thumb-label="props">
                  {{ season(props.value) }}
                </template>
              </v-range-slider>

              <v-divider class="pa-0 ma-0"></v-divider>

            </div>
          </div> -->
          <div class="box-content" style="margin-top: -30px">

            <div class="box-container">
              <v-row>
                <v-col cols="12">
                  <v-data-iterator :items="items" :items-per-page.sync="itemsPerPage" :page.sync="page" hide-default-footer>
                    <template v-slot:default="props">
                      <v-row>
                        <v-col v-for="(item, index) in props.items" :key="index++" cols="12" sm="6" md="6" lg="4" xl="3">
                          <CardCart :content-img="item.img" :content-text="item.title" :amount="item.amount" :contentRating="item.rating" :counter="item.qty" @cartRemove="removeCart(item)" @cartAdd="addCart(item)">
                          </CardCart>
                        </v-col>
                      </v-row>
                    </template>

                    <template v-slot:footer>

                      <v-row v-if="defaultLayout == 'default'">
                        <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="(item, index) in produtos" :key="index++">
                          <!-- <CardCart cardHoverShadow :content-img="item.imagem_produto" :content-text="item.designacao" :counter="item.qty" @cartRemove="removeCart(item)" @cartAdd="addCart(item)" :amount="item.preco_venda" :produto="item" />
                           -->
                          <CardCart cardHoverShadow :content-img="item.imagem_produto" :content-text="item.designacao" :counter="item.qty" @cartRemove="removeCart(item)" @cartAdd="addCart(item)" :amount="item.preco_venda" :produto="item" />
                          <!-- <CardCart cardHoverShadow :content-img="item.imagem_produto" :content-text="item.designacao" :counter="item.qty" @cartRemove="removeCart(item)" @cartAdd="addCart(item)" :amount="item.preco_venda" :produto="item" /> -->
                          <!-- <h6>{{ item }}</h6> -->
                        </v-col>
                      </v-row>

                      <v-container v-else>
                        <v-row name="list-products">
                          <!-- First V-Card -->
                          <v-col cols="12">
                            <v-card elevation="0" v-for="(produto, index) in produtos" :key="index++" class="mb-2">
                              <v-card-text class="ps-6 d-flex justify-space-between align-center flex-wrap">
                                <router-link class="custom-link" :to="{
                                    name: 'ProdutoDetalhes',
                                    params: { produto_id: produto.uuid },
                                  }">
                                  <v-list-item>
                                    <v-list-item-avatar size="80" :title="produto.designacao" rounded>
                                      <v-img :alt="produto.designacao" :src="SERVER + produto.imagem_produto"></v-img>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                      <v-list-item-title class="font-weight-bold text-uppercase">{{
                                          produto.designacao
                                        }}</v-list-item-title>
                                      <v-list-item-subtitle class="text-subtitle2 font-weight-medium">
                                        {{ formatePrice(produto.preco_venda) }}
                                        AKZ</v-list-item-subtitle>
                                      <v-list-item-subtitle>
                                        <v-rating :value="
                                            produto.classificacao
                                              .num_classificacao == null ||
                                            !produto.classificacao
                                              .num_classificacao
                                              ? 5
                                              : parseInt(
                                                  produto.classificacao
                                                    .num_classificacao
                                                )
                                          " color="amber" background-color="amber" dense half-increments readonly size="12"></v-rating>
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                </router-link>
                              </v-card-text>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-container>
                    </template>
                  </v-data-iterator>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CardCart from "@/components/cartCard/CardCart";
import { BASE_URL, SERVIDOR, LOGIN_URL } from "@/configs/api";
import axios from "axios";

export default {
  components: {
    CardCart,
  },
  data() {
    return {
      isWeb:false,
      SERVER: SERVIDOR,
      defaultLayout: "default",
      categories: [],
      itemsLink: [
        {
          text: "Início",
          disabled: false,
          href: "/",
        },
        {
          text: "Pesquisa de Produtos",
          disabled: true,
          href: "/",
        },
      ],

      ordersBy: [
        // { id: "min", designacao: "Preço: o mais barato primeiro" },
        // { id: "max", designacao: "Preço: o mais caro primeiro" },
        // { id: "asc", designacao: "Nome de Produto: A a Z" },
        // { id: "desc", designacao: "Nome de Produto: Z a A" },
      ],
      params: "",
      loading: false,
      offline: false,
      produtos: [],
      keyword: "",
      isSidebar: false,
      page: 1,
      itemsPerPage: 8,
      range: [0, 100, 500, 1000],
      itemsTwo: ["Mais caro", "Mais barato"],
      select: "",
      cartCount: 0,
      filterProductList: [],
      filterProduct: [],
      selected: [],
    };
  },
  computed: {
    ...mapGetters(["getProducts", "getCategory", "getRatings"]),
    numberOfPages() {
      return Math.ceil(this.items.length / this.itemsPerPage);
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    this.items = this.getProducts;
    // this.items = this.getProducts.slice(0, 15).map(n => n);
    this.getAllProdutosPesquisa();
    this.getAllCategories();
     this.isWeb = this.$vuetify.breakpoint.name === 'xl' || this.$vuetify.breakpoint.name === 'lg' || this.$vuetify.breakpoint.name === 'md'
   
  },
  mounted() {
    this.keyword = this.$route.params.keyword;
    this.getOrdersLabels();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    ...mapActions(["addCart", "removeCart"]),

    formatePrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    showDefaultList(params) {
      //  alert(params)
      this.defaultLayout = params;
      // alert(this.defaultLayout)
    },
    getAllCategories() {
      this.loading = true;
      this.$axios
        .get(BASE_URL + "/listarCategorias")
        .then((response) => {
          // let categorias = response.data
          // if (categorias.sub_categorias){
          //   alert("SUBCATEGORIAS")
          // }
          this.categories = response.data;
          // alert(JSON.stringify(response.data));

          setTimeout(() => {
            this.loading = false;
          }, 1500);
        })
        .catch((error) => {});
    },
    getOrdersLabels() {
      axios
        .get(`${LOGIN_URL}/listarOrderByProdutos`)
        .then((response) => {
          this.ordersBy = response.data;
        })
        .catch((error) => {
          console.log(`error ${error}`);
        });
    },

    orderByParameter() {
      let params = this.params;
      axios
        .get(
          BASE_URL +
            `/produtos/pesquisar/${this.$route.params.keyword}?orderBy=${params}`
        )
        .then((response) => {
          this.produtos = response.data;
          // alert(JSON.stringify(response.data));
          setTimeout(() => {
            this.loading = false;
          }, 1500);
        });
    },
    getAllProdutosPesquisa() {
      this.loading = true;
      this.$axios
        .get(BASE_URL + "/produtos/pesquisar/" + this.$route.params.keyword)
        .then((response) => {
          this.produtos = response.data;
          // alert(JSON.stringify(response.data));
          setTimeout(() => {
            this.loading = false;
          }, 2000);
        })
        .catch((error) => {
          this.offline = true;
          // alert("NO CONNECTION!");
          setTimeout(() => {
            this.loading = false;
          }, 2000);
        });
    },

    orderProdutosBy(params) {
      this.loading = true;
      this.$axios
        .get(BASE_URL + "/produtos/pesquisar/" + this.$route.params.keyword)
        .then((response) => {
          this.produtos = response.data;
          setTimeout(() => {
            this.loading = false;
          }, 2000);
        })
        .catch((error) => {
          this.offline = true;
          setTimeout(() => {
            this.loading = false;
          }, 2000);
        });
    },
    relevance(amount) {
      if (this.select == "Low to High") {
        this.items = this.items.sort((a, b) =>
          a[amount] < b[amount] ? -1 : 1
        );
      } else {
        this.items = this.items.sort((a, b) =>
          a[amount] > b[amount] ? -1 : 1
        );
      }
    },
    // fullProducts () {
    //   this.items = this.getProducts
    // },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    season(val) {
      return this.range[val];
    },

    filterCategory(category) {
      this.items = this.getProducts.filter((x) =>
        this.selected.includes(x.category)
      );
      if (this.selected.length <= 0) {
        this.items = this.getProducts;
      }
    },
    filterRatings(rating) {
      this.items = this.getProducts.filter((x) =>
        this.selected.includes(x.rating)
      );
      if (this.selected.length <= 0) {
        this.items = this.getProducts;
      }
    },
    productRate(rate) {
      this.items = this.getProducts.filter((x) => x.rating == rate.rates);
      // this.items = this.filterProduct.sort((a, b) => a[rate.rates] < b[rate.rates] ? -1 : 1)
    },
  },
};
</script>

<style>
.custom-link {
  text-decoration: none;
  /* Remove default text decoration */
  color: #000;
  /* Set the link color */
}

.custom-link:visited {
  /* Style for visited link (after it has been clicked) */
  text-decoration: none;
  /* Remove text decoration */
  color: #555;
  /* Set the color for visited links */
}
</style>
